import React from 'react';
import { Container } from 'react-bootstrap';

function HomePage() {
  return (
    <div id="load-overlay" className="content" style={{ display: 'none' }}>
      <Container>
        <div className="d-flex align-items-center justify-content-center text-center flex-column">
          <h1 className="mt-5">Invalid Access</h1>
          <h2 className="mt-5">Please close this window</h2>
          <br />
          <h2 className="mt-5">If you believe you have reached this page in error, please contact LendPro Support at (855)695-3637</h2>
        </div>
      </Container>
    </div>
  );
}

HomePage.propTypes = {};

export default HomePage;
